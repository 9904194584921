// Imports
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import WeatherInfo from './WeatherInfo';
import jsPDF from 'jspdf';
import { LeftCircleOutlined, RightCircleOutlined, PrinterOutlined, AreaChartOutlined, DatabaseOutlined, BarChartOutlined, PlusOutlined, FileTextOutlined, EyeOutlined } from '@ant-design/icons';
import { Divider, Modal, DatePicker, Tag, Input, Select, Tooltip, Button, message } from 'antd';
import dayjs from 'dayjs';
import WeeklySummaryModal from './WeeklySummaryModal';
import GeneralNotesModal from './GeneralNotesModal';
import ViewNotesModal from './ViewNotesModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

const LATITUDE = 44.854031;
const LONGITUDE = -93.460167;
const API_KEY = '0de005293c6af251d2b471f3a70afbb6';
const GOOGLE_MAPS_API_KEY = 'AIzaSyABgufb7xtnBQs28DlCjxvuVVyul8U1vzM';

// insertTime Function
const insertTime = function() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const currentTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    
    const editor = this.quill;
    const range = editor.getSelection();
    if (range) {
        // Insert a new line if we're not at the start of the document
        if (range.index > 0) {
            editor.insertText(range.index, '\n');
        }
        // Insert bullet point and time without indentation
        editor.insertText(range.index + 1, '• ' + currentTime + ' ');
        // Move cursor to the end of the inserted text
        editor.setSelection(range.index + currentTime.length + 3); // +3 for the bullet and spaces
    }
};

// Tag save and fetch functionality
const saveTag = async (tag) => {
    try {
        const response = await axios.post('https://omniscient21.com:5003/save-tag', tag);
        return response.data;
    } catch (error) {
        console.error('Error saving tag:', error);
        throw error;
    }
};

const fetchTags = async () => {
    try {
        const response = await axios.get('https://omniscient21.com:5003/tags');
        return response.data;
    } catch (error) {
        console.error('Error fetching tags:', error);
        throw error;
    }
};

// Adding a custom Quill module
Quill.register('modules/insertTime', insertTime);

// MainPage funtion
function MainPage() {
    const navigate = useNavigate();

    const getCurrentTime = () => {
        const now = new Date();
        return now.toTimeString().substring(0, 5);
    };

    const isFutureDate = (dateString) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const checkDate = new Date(dateString);
        return checkDate > today;
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const adjustedDate = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
        return adjustedDate.toLocaleDateString(undefined, options);
    };

    // Modal Dialog Processing
    const [isModalVisible, setIsModalVisible] = useState(false);  // Controls modal visibility
    const [printRange, setPrintRange] = useState([null, null]);   // Holds the selected date range

    // Function to show the modal when Print is clicked
    const handlePrintClick = () => {
        setIsModalVisible(true);
    };

    // Function to handle the modal submission (OK button)
    const handleModalOk = () => {
        if (printRange[0] && printRange[1]) {
            const startDate = dayjs(printRange[0]).format('YYYY-MM-DD');
            const endDate = dayjs(printRange[1]).format('YYYY-MM-DD');
            handlePrint(startDate, endDate);
        }
        setIsModalVisible(false);
    };

    const handlePrint = async (startDate, endDate) => {
        try {
            const response = await axios.get(`https://omniscient21.com:5003/print-data/${startDate}/${endDate}`);
            console.log('Print data received:', response.data);
            generatePDF(response.data);
        } catch (error) {
            console.error('Error fetching print data:', error);
        }
    };

    // Function to close the modal without submitting
    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    // Function to store the selected date range
    const handleRangeChange = (dates) => {
        setPrintRange(dates);
    };

    // getDefaultData Function
    const getDefaultData = () => ({
        arise: null,
        sleepQuality: 0,
        depression: 0,
        headache: 0,
        bedtime: null,
        notes: '',
        dietCoke: [{ time: null }],
        milk: [{ time: null }],
        foodConsumed: '',
        cheatDay: null,
        mood: [{ feeling: 0, flag: false, redZone: false, time: getCurrentTime(), notes: '', tags: [] }],
        exercise: { walkingMiles: 0, ruckWeight: 0, resistance: false },
        medication: [{ medication: '', quantity: '', time: null }],
        weather: null,
    });

    // General State Variables
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [data, setData] = useState(getDefaultData());
    const [isViewNotesVisible, setIsViewNotesVisible] = useState(false);
    const [isGeneralNotesVisible, setIsGeneralNotesVisible] = useState(false);
    const [allNotes, setAllNotes] = useState([]);
    const [generalNotes, setGeneralNotes] = useState('');

    // New Tag State variables
    const [allTags, setAllTags] = useState([]);
    const [inputVisible, setInputVisible] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Default');
    const [tagCategories, setTagCategories] = useState([
        { name: 'Physical', color: '#1a9cd4' },
        { name: 'Negative', color: '#f50' },
        { name: 'Positive', color: '#87d068' },
]);

    // Tag functions
    const handleClose = (removedTag, moodIndex) => {
    const updatedMood = [...data.mood];
    updatedMood[moodIndex].tags = updatedMood[moodIndex].tags.filter(tag => 
        !(tag.text === removedTag.text && tag.category === removedTag.category)
    );
    setData(prevData => ({
        ...prevData,
        mood: updatedMood
    }));
    handleFieldChange('mood', `tags${moodIndex + 1}`, updatedMood[moodIndex].tags);
};

    const showInput = (moodIndex) => {
        setInputVisible(prevState => ({
            ...prevState,
            [moodIndex]: true
        }));
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = (moodIndex) => {
        if (inputValue && !data.mood[moodIndex].tags.some(tag => tag.text === inputValue)) {
            const newTag = { text: inputValue, category: selectedCategory };
            const updatedMood = [...data.mood];
            updatedMood[moodIndex].tags = [...(updatedMood[moodIndex].tags || []), newTag];

            setData(prevData => ({
                ...prevData,
                mood: updatedMood
            }));

            setAllTags(prevAllTags => {
                if (!prevAllTags.some(tag => tag.text === inputValue && tag.category === selectedCategory)) {
                    return [...prevAllTags, newTag];
                }
                return prevAllTags;
            });

            handleFieldChange('mood', `tags${moodIndex + 1}`, updatedMood[moodIndex].tags);
        }

        // Fix: Change prevState to prev
        setInputVisible(prev => ({
            ...prev,
            [moodIndex]: false
        }));
        setInputValue('');
    };

    const handleEditInputChange = e => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = (moodIndex) => {
        const newTags = [...data.mood[moodIndex].tags];
        newTags[editInputIndex] = { ...newTags[editInputIndex], text: editInputValue };
        const updatedMood = [...data.mood];
        updatedMood[moodIndex].tags = newTags;
        setData(prevData => ({
            ...prevData,
            mood: updatedMood
        }));
        handleFieldChange('mood', `tags${moodIndex + 1}`, newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    const handleTagSelect = (selectedTags, moodIndex) => {
        console.log('Selected tags:', selectedTags);
        
        const updatedTags = selectedTags
            .map(tagStr => {
                try {
                    const parsedTag = typeof tagStr === 'string' ? JSON.parse(tagStr) : tagStr;
                    return allTags.find(t => 
                        t.text === parsedTag.text && t.category === parsedTag.category
                    ) || parsedTag;
                } catch (error) {
                    console.error('Error parsing tag:', error, tagStr);
                    return null;
                }
            })
            .filter(tag => tag !== null);

        setData(prevData => {
            const updatedMood = [...prevData.mood];
            updatedMood[moodIndex] = {
                ...updatedMood[moodIndex],
                tags: updatedTags
            };
            return {
                ...prevData,
                mood: updatedMood
            };
        });
        
        handleFieldChange('mood', `tags${moodIndex + 1}`, updatedTags);
    };

    const addNewTag = async (moodIndex) => {
        if (inputValue && !allTags.some(tag => tag.text === inputValue && tag.category === selectedCategory)) {
            const newTag = { text: inputValue, category: selectedCategory };
            
            try {
                await saveTag(newTag);
                setAllTags(prevTags => [...prevTags, newTag]);
                
                // Create updated mood array with new tag
                setData(prevData => {
                    const updatedMood = [...prevData.mood];
                    updatedMood[moodIndex] = {
                        ...updatedMood[moodIndex],
                        tags: [
                            ...(updatedMood[moodIndex].tags || []),
                            newTag
                        ]
                    };
                    return {
                        ...prevData,
                        mood: updatedMood
                    };
                });

                // Wait for state update before sending to server
                const updatedTags = [
                    ...(data.mood[moodIndex].tags || []),
                    newTag
                ];
                handleFieldChange('mood', `tags${moodIndex + 1}`, updatedTags);
                setInputValue('');
            } catch (error) {
                console.error('Error saving new tag:', error);
            }
        }
    };

    const deleteTag = async (tag) => {
    try {
        const response = await axios.post('https://omniscient21.com:5003/delete-tag', tag);
        return response.data;
    } catch (error) {
        if (error.response?.data?.inUse) {
            throw new Error('Tag is in use and cannot be deleted');
        }
        throw new Error('Error deleting tag');
    }
};

    const updateSelectedCategory = (value) => {
        setSelectedCategory(value);
    };

    const updateAllTags = async (newTags) => {
    try {
        const existingTags = new Set(allTags.map(tag => JSON.stringify({ text: tag.text, category: tag.category })));
        const uniqueNewTags = newTags.filter(newTag => 
            !existingTags.has(JSON.stringify({ text: newTag.text, category: newTag.category }))
        );
        
        await Promise.all(uniqueNewTags.map(tag => saveTag(tag)));
        setAllTags(prevAllTags => [...prevAllTags, ...uniqueNewTags]);
    } catch (error) {
        console.error('Error updating tags:', error);
    }
};

    // fetchDataForDate function
    const fetchDataForDate = async (selectedDate) => {
    console.log('Fetching data for date:', selectedDate);
    try {
        const [dataResponse, tagsResponse] = await Promise.all([
            axios.get(`https://omniscient21.com:5003/data/${selectedDate}`),
            axios.get(`https://omniscient21.com:5003/mood-tags/${selectedDate}`)
        ]);

        console.log('Data received from server:', dataResponse.data);
        console.log('Tags received from server:', tagsResponse.data);

        let newData = getDefaultData();
        let allUniqueTags = new Set();

        if (dataResponse.data && dataResponse.data.length) {
            dataResponse.data.forEach(item => {
                if (item.section === 'weather') {
                    if (!newData.weather) newData.weather = {};
                    newData.weather[item.field] = item.value;
                } else if (item.section === 'dietCoke' || item.section === 'milk') {
                    const index = parseInt(item.field.replace('time', '')) - 1;
                    newData[item.section][index] = { time: item.value };
                } else if (item.section === 'mood') {
                    const [subField, index] = item.field.match(/([a-zA-Z]+)(\d+)/).slice(1);
                    const moodIndex = parseInt(index) - 1;
                    if (!newData.mood[moodIndex]) newData.mood[moodIndex] = {};
                    if (subField === 'redZone' || subField === 'flag') {
                        newData.mood[moodIndex][subField] = parseBoolean(item.value);
                    } else {
                        newData.mood[moodIndex][subField] = item.value;
                    }
                } else if (item.section === 'medication') {
                    const [subField, index] = item.field.match(/([a-zA-Z]+)(\d+)/).slice(1);
                    const medIndex = parseInt(index) - 1;
                    if (!newData.medication[medIndex]) newData.medication[medIndex] = {};
                    newData.medication[medIndex][subField] = item.value;
                } else if (item.section === 'exercise') {
                    if (item.field === 'resistance') {
                        newData.exercise.resistance = parseBoolean(item.value);
                    } else {
                        newData.exercise[item.field] = item.value;
                    }
                } else if (item.section === 'Food' && item.field === 'cheatDay') {
                    newData.cheatDay = parseBoolean(item.value);
                } else if (item.section === 'Sleep' && item.field === 'sleepQuality') {
                    newData.sleepQuality = parseFloat(item.value);
                } else {
                    newData[item.field] = item.value;
                }
            });
        }

        // Incorporate tags data
        if (tagsResponse.data && tagsResponse.data.length) {
            tagsResponse.data.forEach(tagItem => {
                const moodIndex = parseInt(tagItem.mood_index) - 1;
                if (newData.mood[moodIndex]) {
                    try {
                        const parsedTags = JSON.parse(tagItem.tags);
                        newData.mood[moodIndex].tags = Array.isArray(parsedTags) ? parsedTags : [];
                        newData.mood[moodIndex].tags.forEach(tag => allUniqueTags.add(JSON.stringify(tag)));
                    } catch (error) {
                        console.error('Error parsing tags:', error);
                        newData.mood[moodIndex].tags = [];
                    }
                }
            });
        }

        // Update allTags state
        updateAllTags(Array.from(allUniqueTags).map(tag => JSON.parse(tag)));

        console.log('New data structure after processing:', newData);
        return newData;
    } catch (error) {
        console.error('Error fetching data:', error);
        return getDefaultData();
    }
};

const getUniqueTags = (tags) => {
    const seen = new Set();
    return tags.filter(tag => {
        const key = `${tag.text}-${tag.category}`;
        if (seen.has(key)) {
            return false;
        }
        seen.add(key);
        return true;
    });
};

// Helper function to parse boolean values
const parseBoolean = (value) => {
    return value === true || value === 'true' || value === 1 || value === '1';
};

const getMoonPhase = (date) => {
    const phase = computeMoonPhase(date);
    if (phase === 0 || phase === 1) return 'New Moon';
    if (phase < 0.25) return 'Waxing Crescent';
    if (phase === 0.25) return 'First Quarter';
    if (phase < 0.5) return 'Waxing Gibbous';
    if (phase === 0.5) return 'Full Moon';
    if (phase < 0.75) return 'Waning Gibbous';
    if (phase === 0.75) return 'Last Quarter';
    return 'Waning Crescent';
};

const computeMoonPhase = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    
    const c = Math.floor(365.25 * year);
    const e = Math.floor(30.6 * month);
    const jd = c + e + day - 694039.09;
    const dp = jd / 29.53;
    return dp - Math.floor(dp);
};

const fetchWeatherData = async (selectedDate) => {
    try {
        // Fetch basic weather data through your backend proxy
        const weatherResponse = await axios.get(
            `https://omniscient21.com:5003/api/weather`, {
            params: { lat: LATITUDE, lon: LONGITUDE }
        });
        
        // Fetch pollen data through your backend proxy
        const pollenResponse = await axios.get(
            `https://omniscient21.com:5003/api/pollen`, {
            params: { lat: LATITUDE, lon: LONGITUDE }
        });

        // Combine the data and return it
        return {
            ...weatherResponse.data,
            pollenLevel: pollenResponse.data.pollenLevel,
            moonPhase: getMoonPhase(new Date(selectedDate))
        };
    } catch (error) {
        console.error("Error fetching weather data:", error);
        return null;
    }
};
    const fetchAllNotes = async () => {
    try {
        const response = await axios.get('https://omniscient21.com:5003/all-general-notes');
        console.log('Raw API response:', response.data);
        if (Array.isArray(response.data)) {
            setAllNotes(response.data);
            return response.data; // Return the data for immediate use
        } else {
            console.error('Unexpected API response format:', response.data);
            setAllNotes([]);
            return [];
        }
    } catch (error) {
        console.error('Error fetching all notes:', error);
        setAllNotes([]);
        return [];
    }
};

    // Handler for the view notes icon click
    const handleViewNotesClick = async () => {
    const notes = await fetchAllNotes();
    if (Array.isArray(notes) && notes.length > 0) {
        setAllNotes(notes); // Set state again to ensure it's up to date
        setIsViewNotesVisible(true);
    } else {
        setAllNotes([]);
        setIsViewNotesVisible(true);
    }
};

    useEffect(() => {
    const loadTags = async () => {
        try {
            const tags = await fetchTags();
            setAllTags(tags);
        } catch (error) {
            console.error('Error loading tags:', error);
        }
    };
    loadTags();
}, []);

    // useEffect for fetchDataForDateWithWeather
    useEffect(() => {
    const fetchDataForDateWithWeather = async () => {
        try {
            const fetchedData = await fetchDataForDate(date);
            
            // Check if weather data exists and includes pollen level
            if (!fetchedData.weather || 
                Object.keys(fetchedData.weather).length === 0 || 
                !fetchedData.weather.pollenLevel) {
                
                if (!isFutureDate(date)) {
                    console.log('No weather data or pollen data found, fetching from APIs');
                    const weatherData = await fetchWeatherData(date);
                    if (weatherData) {
                        // Save each weather field to the database
                        Object.entries(weatherData).forEach(([field, value]) => {
                            sendToServer('weather', field, value);
                        });

                        // Merge new weather data with existing data
                        const updatedData = {
                            ...fetchedData,
                            weather: {
                                ...(fetchedData.weather || {}),
                                ...weatherData
                            }
                        };
                        setData(updatedData);
                        
                        // Log the updated weather data
                        console.log('Updated weather data:', updatedData.weather);
                    } else {
                        setData(fetchedData);
                    }
                } else {
                    console.log('Future date, not fetching weather data');
                    setData({...fetchedData, weather: null});
                }
            } else {
                console.log('Complete weather data found in database:', fetchedData.weather);
                setData(fetchedData);
            }
        } catch (error) {
            console.error('Error in fetchDataForDateWithWeather:', error);
            setData(getDefaultData());
        }
    };

    fetchDataForDateWithWeather();
}, [date]);

    const handleSaveGeneralNotes = async () => {
        try {
            await axios.post('https://omniscient21.com:5003/save-general-notes', {
                date: date,
                notes: generalNotes
            });
            setIsGeneralNotesVisible(false);
            // Clear the notes after saving
            setGeneralNotes('');
        } catch (error) {
            console.error('Error saving general notes:', error);
        }
    };

    const formatTime = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const calculateDaylight = (sunrise, sunset) => {
        const daylightSeconds = sunset - sunrise;
        const hours = Math.floor(daylightSeconds / 3600);
        const minutes = Math.floor((daylightSeconds % 3600) / 60);
        return `${hours}h ${minutes}m`;
    };

    // Handler for the notes icon click to ensure the field is empty when opened
    const handleNotesIconClick = () => {
    setGeneralNotes(''); // Ensure the field is empty
    setIsGeneralNotesVisible(true);
};

    // handleFieldChange
    const handleFieldChange = (section, field, value) => {
    console.log(`Handling field change for section: ${section}, field: ${field}, value:`, value);

    setData(prevData => {
        const newData = { ...prevData };

        if (section === 'mood') {
            const [subField, index] = field.match(/([a-zA-Z]+)(\d+)/).slice(1);
            const moodIndex = parseInt(index) - 1;
            if (subField === 'redZone' || subField === 'flag') {
                newData.mood[moodIndex] = { ...newData.mood[moodIndex], [subField]: value };
            } else if (subField === 'tags') {
                newData.mood[moodIndex] = { ...newData.mood[moodIndex], tags: value };
            } else {
                newData.mood[moodIndex] = { ...newData.mood[moodIndex], [subField]: value };
            }
        } else if (section === 'dietCoke' || section === 'milk') {
            const index = parseInt(field.replace('time', '')) - 1;
            newData[section][index] = { ...newData[section][index], time: value };
        } else if (section === 'medication') {
            const [subField, index] = field.match(/([a-zA-Z]+)(\d+)/).slice(1);
            const medIndex = parseInt(index) - 1;
            newData.medication[medIndex] = { ...newData.medication[medIndex], [subField]: value };
        } else if (section === 'exercise') {
            newData.exercise = { ...newData.exercise, [field]: value };
        } else if (section === 'Food' && field === 'cheatDay') {
            newData.cheatDay = value;
        } else if (section === 'Food') {
            newData[field] = value;
        } else {
            newData[field] = value;
        }

        console.log('Updated data:', newData);
        return newData;
    });

    // Send updated data to the server
    if (section === 'mood' && field.startsWith('tags')) {
        // Stringify tags before sending to server
        sendToServer(section, field, JSON.stringify(value));
    } else {
        sendToServer(section, field, value);
    }
};
    
    // Send data to server function
    const sendToServer = (section, field, value) => {
    console.log('Sending data to server:', { section, field, value, date });
    const formattedDate = new Date(date).toISOString().split('T')[0];

    let payload;
    if (section === 'mood' && field.startsWith('tags')) {
        const [, index] = field.match(/tags(\d+)/);
        payload = { section, field: `tags${index}`, value: JSON.stringify(value), date: formattedDate };
    } else {
        payload = { section, field, value, date: formattedDate };
    }
    console.log('Payload being sent to server:', payload);

    axios.post('https://omniscient21.com:5003/save', payload)
        .then(response => {
            console.log('Data saved successfully:', response.data);
        })
        .catch(error => {
            console.error('Error saving data:', error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            // You might want to add some user feedback here, e.g. a toast notification
        });
};

    // handleAddEntry Function
    const handleAddEntry = (section) => {
        setData(prevData => {
            let newData = { ...prevData };
            let newEntry;
            if (section === 'dietCoke' || section === 'milk') {
                newEntry = { time: getCurrentTime() };
                newData[section] = [...newData[section], newEntry];

                // Save the new entry to the server
                const index = newData[section].length;
                sendToServer(section, `time${index}`, newEntry.time);
            } else if (section === 'mood') {
                newEntry = { feeling: 0, flag: false, redZone: false, time: getCurrentTime(), notes: '' };
                newData.mood = [...newData.mood, newEntry];

                // Save the new entry to the server
                const index = newData.mood.length;
                Object.entries(newEntry).forEach(([key, value]) => {
                    sendToServer(section, `${key}${index}`, value);
                });
            } else if (section === 'medication') {
                newEntry = { medication: '', quantity: '', time: getCurrentTime() };
                newData.medication = [...newData.medication, newEntry];

                // Save the new entry to the server
                const index = newData.medication.length;
                Object.entries(newEntry).forEach(([key, value]) => {
                    sendToServer(section, `${key}${index}`, value);
                });
            }

            return newData;
        });
    };

    // handleRemoveEntry Function
    const handleRemoveEntry = (section, index) => {
        setData(prevData => {
            const newData = { ...prevData };
            if (newData[section].length > 1) {
                newData[section].splice(index, 1);
            }
            return newData;
        });

        const fieldsToDelete = [];
        if (section === 'mood') {
            fieldsToDelete.push(`feeling${index + 1}`, `flag${index + 1}`, `redZone${index + 1}`, `time${index + 1}`, `notes${index + 1}`);
        } else if (section === 'dietCoke' || section === 'milk') {
            fieldsToDelete.push(`time${index + 1}`);
        } else if (section === 'medication') {
            fieldsToDelete.push(`medication${index + 1}`, `quantity${index + 1}`, `time${index + 1}`);
        }

        const deleteRequests = fieldsToDelete.map(field =>
            axios.post('https://omniscient21.com:5003/delete', { section, field, date })
        );

        Promise.all(deleteRequests)
            .then(() => {
                console.log(`Successfully deleted ${section} entry at index ${index}`);
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
    };
    // handleDateChange Function
    const handleDateChange = (direction) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + direction);
    setDate(currentDate.toISOString().split('T')[0]);
};

    // fetchWeeklySummaryData Function
    const fetchWeeklySummaryData = async () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate last Monday (or today if it's Monday)
    const monday = new Date(today);
    monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

    // Calculate last Sunday
    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);

    const dateRange = `${monday.toLocaleDateString()} - ${sunday.toLocaleDateString()}`;

    console.log(`Fetching data for date range: ${monday.toISOString().split('T')[0]} to ${sunday.toISOString().split('T')[0]}`);

    try {
        const response = await axios.get(`https://omniscient21.com:5003/weekly-summary/${monday.toISOString().split('T')[0]}/${sunday.toISOString().split('T')[0]}`);
        const summaryData = response.data;
        console.log("Summary data received:", summaryData);

        // The server now sends us already processed data, so we can use it directly
        setWeeklySummaryData(summaryData);
        setDateRange(dateRange);
        setIsWeeklySummaryVisible(true);
    } catch (error) {
        console.error('Error fetching weekly summary data:', error);
        console.error('Error details:', error.response?.data);
    }
};

    // State variable for weekly summary page
    const [isWeeklySummaryVisible, setIsWeeklySummaryVisible] = useState(false);
    const [weeklySummaryData, setWeeklySummaryData] = useState(null);
    const [dateRange, setDateRange] = useState('');

    const averageTime = (times) => {
    const validTimes = times.filter(time => time);
    if (validTimes.length === 0) return 'N/A';
    const totalMinutes = validTimes.reduce((sum, time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return sum + hours * 60 + minutes;
    }, 0);
    const averageMinutes = Math.round(totalMinutes / validTimes.length);
    const hours = Math.floor(averageMinutes / 60);
    const minutes = averageMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
};

    // generatePDF Function
    const generatePDF = (data) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        let yOffset = 10;
        let totalPages = Object.keys(data).length; // Get total number of pages

    // Helper function to parse HTML content
    const parseHtmlContent = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        let parsedContent = [];

        const parseNode = (node, indent = 0, listType = null) => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent.trim();
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                switch (node.tagName.toLowerCase()) {
                    case 'ul':
                        listType = 'ul';
                        return Array.from(node.childNodes).map(child => parseNode(child, indent, listType)).join('\n');
                    case 'ol':
                        listType = 'ol';
                        return Array.from(node.childNodes).map((child, index) => parseNode(child, indent, listType, index + 1)).join('\n');
                    case 'li':
                        const bullet = listType === 'ul' ? '•' : `${arguments[3]}.`;
                        const content = Array.from(node.childNodes).map(child => parseNode(child, indent + 1)).join(' ').trim();
                        const indentString = ' '.repeat(indent * 4);
                        const bulletString = `${indentString}${bullet} `;
                        const contentLines = pdf.splitTextToSize(content, 180 - (indent + 1) * 4);
                        return bulletString + contentLines.join(`\n${' '.repeat(bulletString.length)}`);
                    default:
                        return Array.from(node.childNodes).map(child => parseNode(child, indent)).join(' ');
                }
            }
            return '';
        };

        const content = parseNode(doc.body);
        return content.split('\n');
    };

        // Helper function to format date
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };

        // Helper function to add bold text
        const addBoldText = (text, x, y) => {
            pdf.setFont('helvetica', 'bold');
            pdf.text(text, x, y);
            pdf.setFont('helvetica', 'normal');
        };

        // Helper function to add page number
        const addPageNumber = (pageNum) => {
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'normal');
            const text = `Page ${pageNum} of ${totalPages}`;
            const textWidth = pdf.getTextWidth(text);
            const x = (pdf.internal.pageSize.width - textWidth) / 2;
            pdf.text(text, x, 287); // 287 mm is near the bottom of an A4 page
        };

        Object.entries(data).forEach(([dateString, dayData], pageIndex) => {
            if (pageIndex > 0) {
                pdf.addPage();
                yOffset = 10;
            }

        // Add page number at the beginning of each page
        addPageNumber(pageIndex + 1);
        yOffset = 10;

        // Add formatted date in bold
        const formattedDate = formatDate(dateString);
        pdf.setFontSize(16);
        addBoldText(formattedDate, 10, yOffset);
        yOffset += 10;

        // Function to add a section with bold headings and labels
        const addSection = (title, content) => {
            pdf.setFontSize(14);
            addBoldText(title, 10, yOffset);
            yOffset += 7;
            pdf.setFontSize(10);
            content.forEach(line => {
                if (typeof line === 'string') {
                    const [label, ...rest] = line.split(':');
                    if (rest.length > 0) {
                        const value = rest.join(':').trim();
                        addBoldText(label + ':', 15, yOffset);
                        pdf.text(': ' + value, 15 + pdf.getTextWidth(label + ': '), yOffset);
                    } else {
                        pdf.text(line, 15, yOffset);
                    }
                    yOffset += 5;
                } else if (Array.isArray(line)) {
                    line.forEach(subline => {
                        pdf.text(subline, 15, yOffset);
                        yOffset += 5;
                    });
                }
                if (yOffset > 280) {
                    pdf.addPage();
                    yOffset = 10;
                    addPageNumber(pageIndex + 2);
                }
            });
            yOffset += 5;
        };

        // Add Weather section
        if (dayData.weather) {
            addSection('Weather', [
                `Temperature: ${dayData.weather.temperature || 'N/A'}°F`,
                `Weather: ${dayData.weather.description || 'N/A'}`,
                `Sunrise: ${dayData.weather.sunrise || 'N/A'}`,
                `Sunset: ${dayData.weather.sunset || 'N/A'}`,
                `Hours of Daylight: ${dayData.weather.hoursOfDaylight || 'N/A'}`
            ]);
        }

        // Add Sleep section
        if (dayData.Sleep) {
            addSection('Sleep', [
                `Arise: ${dayData.Sleep.arise || 'Not set'}`,
                `Sleep Quality: ${dayData.Sleep.sleepQuality || 'N/A'}`,
                `Depression: ${dayData.Sleep.depression || 'N/A'}`,
                `Headache: ${dayData.Sleep.headache || 'N/A'}`,
                `Bedtime: ${dayData.Sleep.bedtime || 'Not set'}`,
                `Notes:`,
                ...parseHtmlContent(dayData.Sleep.notes || 'None')
            ]);
        }

            // Add Diet Coke section
            if (dayData.dietCoke) {
                const dietCokeEntries = Object.entries(dayData.dietCoke)
                    .map(([key, value]) => `${key}: ${value}`);
                addSection('Diet Coke', dietCokeEntries.length > 0 ? dietCokeEntries : ['No Diet Coke entries']);
            }

            // Add Milk section
            if (dayData.milk) {
                const milkEntries = Object.entries(dayData.milk)
                    .map(([key, value]) => `${key}: ${value}`);
                addSection('Milk', milkEntries.length > 0 ? milkEntries : ['No Milk entries']);
            }

            // Add Food section
            if (dayData.Food) {
                addSection('Food', [
                    `Cheat Day: ${dayData.Food.cheatDay ? 'Yes' : 'No'}`,
                    ...parseHtmlContent(dayData.Food.foodConsumed || 'No food recorded')
                ]);
            }

            // Add Mood section
            if (dayData.mood) {
                Object.entries(dayData.mood).forEach(([key, value], index) => {
                    if (key.startsWith('feeling')) {
                        const moodIndex = key.replace('feeling', '');
                        addSection(`Mood ${moodIndex}`, [
                            `Feeling: ${value || 'N/A'}`,
                            `Time: ${dayData.mood[`time${moodIndex}`] || 'N/A'}`,
                            `Notes:`,
                            parseHtmlContent(dayData.mood[`notes${moodIndex}`] || 'None')
                        ]);
                    }
                });
            }

            // Add Exercise section
            if (dayData.exercise) {
                addSection('Exercise', [
                    `Walking Miles: ${dayData.exercise.walkingMiles || 'N/A'}`,
                    `Ruck Weight (lbs): ${dayData.exercise.ruckWeight || 'N/A'}`,
                    `Resistance?: ${dayData.exercise.resistance ? 'Yes' : 'No'}`
                ]);
            }

            // Add Medication section
            if (dayData.medication) {
                Object.entries(dayData.medication).forEach(([key, value], index) => {
                    if (key.startsWith('medication')) {
                        const medIndex = key.replace('medication', '');
                        addSection(`Medication ${medIndex}`, [
                            `Medication: ${value || 'Not specified'}`,
                            `Qty: ${dayData.medication[`quantity${medIndex}`] || 'Not specified'}`,
                            `Time: ${dayData.medication[`time${medIndex}`] || 'Not specified'}`
                        ]);
                    }
                });
            }

        });

        pdf.save('health_tracker_report.pdf');
    };

    // Medications Picklist Values
    const medicationOptions = [
        "None",
        "Dexmethyphenidate HCL ER Cap 5 Mg",
        "Methylphenidate 5 MG Tablet",
        "Methylphenidate 10 MG Tablet",
        "Methylphenidate 18 MG ER Tablet",
        "Trulicity 0.75 mg/0.5 MediaList",
        "Methylphenidate 20 MG Tablet",
        "Short Acting Insulin",
        "Long Acting Insulin",
        "Excedrine Migraine Relief 250mg Caplet",
        "Aleve 220mg Caplet",
        "Vitamin D"
    ];

    const modulesWithTime = {
        toolbar: {
            container: [
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image'],
                [{ 'insertTime': 'Time' }]
            ],
            handlers: {
                insertTime: insertTime
            }
        }
    };

    const modulesWithoutTime = {
        toolbar: {
            container: [
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image']
            ]
        }
    };

    // handleViewData Function
    const handleViewData = () => {
        navigate('/view-data'); // Add navigation to ViewData component
    };

      // handleViewGraph Function
    const handleViewGraph = () => {
        navigate('/view-graph'); // Navigate to the View Graph page
    };

    // return
    return (
        <div className="container">

            {/* Header Section */}
            <div className="header-wrapper">
                <header>
                    <LeftCircleOutlined
                        onClick={() => handleDateChange(-1)}
                        className="date-change-icon"
                    />
                    <div className="date-container">
                        <div className="date-display">{formatDate(date)}</div>
                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </div>
                    <RightCircleOutlined
                        onClick={() => handleDateChange(1)}
                        className="date-change-icon"
                    />
                </header>
            </div>

            {/* Weather Section */}
            <section className="weather-section">
                <h2>Weather</h2>
                {isFutureDate(date) ? (
                    <p>Weather data not available for future dates.</p>
                ) : (
                    <WeatherInfo weatherData={data.weather} />
                )}
            </section>

            {/* Sleep Section */}
            <section>
                <h2>Sleep</h2>
                <div className="sleep-row">
                    <div className="form-group">
                        <label>Arise</label>
                        <input 
                            type="time" 
                            value={data.arise || ''} 
                            onChange={(e) => handleFieldChange('Sleep', 'arise', e.target.value)} 
                            step="900"
                        />
                    </div>
                    <div className="form-group">
                        <label>Sleep Quality</label>
                        <input 
                            type="number" 
                            step="0.25" 
                            min="0" 
                            max="5" 
                            value={data.sleepQuality || 0} 
                            onChange={(e) => handleFieldChange('Sleep', 'sleepQuality', parseFloat(e.target.value))} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Depression</label>
                        <input 
                            type="number" 
                            step="0.25" 
                            min="0" 
                            max="5" 
                            value={data.depression} 
                            onChange={(e) => handleFieldChange('Sleep', 'depression', e.target.value)} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Headache</label>
                        <input 
                            type="number" 
                            step="1" 
                            min="0" 
                            max="5" 
                            value={data.headache} 
                            onChange={(e) => handleFieldChange('Sleep', 'headache', e.target.value)} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Bedtime</label>
                        <input 
                            type="time" 
                            value={data.bedtime || ''} 
                            onChange={(e) => handleFieldChange('Sleep', 'bedtime', e.target.value)} 
                            step="900"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Notes</label>
                    <ReactQuill 
                        className="quill sleep-quill"
                        value={data.notes} 
                        onChange={(value) => handleFieldChange('Sleep', 'notes', value)} 
                        modules={modulesWithoutTime}
                    />
                </div>
            </section>

            {/* Beverages Section */ }
            <section>
                <h2>Beverages</h2>
                <div className="beverages-container">
                    {/* Diet Coke Column */}
                    <div className="beverage-column">
                        <h3>Diet Coke</h3>
                        {data.dietCoke.map((entry, index) => (
                            <div key={index}>
                                <div className="beverage-row">
                                    <div className="form-group">
                                        <label>Diet Coke {index + 1}</label>
                                        <input
                                            type="time"
                                            value={entry.time || ''}
                                            onChange={(e) => handleFieldChange('dietCoke', `time${index + 1}`, e.target.value)}
                                            step="900"
                                            min="00:00"
                                        />
                                    </div>
                                    <div className="beverage-buttons">
                                        <button onClick={() => handleAddEntry('dietCoke')}>+</button>
                                        <button 
                                            onClick={() => handleRemoveEntry('dietCoke', index)} 
                                            disabled={index === 0 || data.dietCoke.length === 1}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                                {index < data.dietCoke.length - 1 && <Divider style={{ margin: '8px 0' }} />}
                            </div>
                        ))}
                    </div>

                    {/* Milk Column */}
                    <div className="beverage-column">
                        <h3>Milk</h3>
                        {data.milk.map((entry, index) => (
                            <div key={index}>
                                <div className="beverage-row">
                                    <div className="form-group">
                                        <label>Milk {index + 1}</label>
                                        <input
                                            type="time"
                                            value={entry.time || ''}
                                            onChange={(e) => handleFieldChange('milk', `time${index + 1}`, e.target.value)}
                                            step="900"
                                            min="00:00"
                                        />
                                    </div>
                                    <div className="beverage-buttons">
                                        <button onClick={() => handleAddEntry('milk')}>+</button>
                                        <button 
                                            onClick={() => handleRemoveEntry('milk', index)} 
                                            disabled={index === 0 || data.milk.length === 1}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                                {index < data.milk.length - 1 && <Divider style={{ margin: '8px 0' }} />}
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Food Section */}
            <section>
                <div className="food-header">
                    <h2>Food</h2>
                    <div className="cheat-day-checkbox">
                        <label>
                        Cheat Day?
                            <input
                                type="checkbox"
                                checked={data.cheatDay || false}
                                onChange={(e) => handleFieldChange('Food', 'cheatDay', e.target.checked)}
                            />
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <ReactQuill 
                        className="quill food-quill"
                        value={data.foodConsumed || ''}
                        onChange={(value) => handleFieldChange('Food', 'foodConsumed', value)}
                        modules={modulesWithTime}
                    />
                </div>
            </section>

            {/* Mood Section */}
            <section>
                <h2>Mood</h2>
                {(data.mood || []).map((entry, index) => (
                    <div className="mood-entry" key={index}>
                        <div className="mood-row">
                            <div className="form-group">
                                <label>Mood {index + 1}</label>
                                <input 
                                    type="number" 
                                    step="0.5" 
                                    min="0" 
                                    max="5" 
                                    value={entry.feeling || 0}
                                    onChange={(e) => handleFieldChange('mood', `feeling${index + 1}`, parseFloat(e.target.value) || 0)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Time</label>
                                <input 
                                    type="time" 
                                    value={entry.time || ''}
                                    onChange={(e) => handleFieldChange('mood', `time${index + 1}`, e.target.value)} 
                                    step="900"
                                />
                            </div>
                            <div className="form-group-flag">
                                <label>Flag:</label>
                                <input 
                                    type="checkbox" 
                                    checked={entry.flag || false}
                                    onChange={(e) => handleFieldChange('mood', `flag${index + 1}`, e.target.checked)} 
                                />
                            </div>
                            <div className="form-group-red-zone">
                                <label>Red Zone:</label>
                                <input 
                                    type="checkbox" 
                                    checked={entry.redZone || false}
                                    onChange={(e) => handleFieldChange('mood', `redZone${index + 1}`, e.target.checked)} 
                                />
                            </div>
                            <div className="mood-buttons">
                                <button onClick={() => handleAddEntry('mood')}>+</button>
                                <button 
                                    onClick={() => handleRemoveEntry('mood', index)} 
                                    disabled={index === 0 || data.mood.length === 1}
                                >
                                    -
                                </button>
                            </div>
                        </div>
                        <div className="mood-tags" style={{ marginTop: '30px', marginBottom: '30px' }}>
                            {/* Display selected tags above the dropdown */}
                            <div className="selected-tags" style={{ marginBottom: '10px' }}>
                                {entry.tags?.map((tag, tagIndex) => (
                                    <Tag
                                        key={tagIndex}
                                        color={tagCategories.find(cat => cat.name === tag.category)?.color}
                                        closable
                                        onClose={() => handleClose(tag, index)}
                                        style={{ marginRight: '5px', marginBottom: '5px' }}
                                    >
                                        {tag.text}
                                    </Tag>
                                ))}
                            </div>

                            {/* Tag selection dropdown */}
                            <Select
                                mode="multiple"
                                style={{ width: '100%', marginBottom: '10px' }}
                                placeholder="Select or create tags"
                                value={entry.tags?.map(tag => JSON.stringify(tag)) || []}
                                maxTagCount={0} // Add this line to hide the selected tags in the input
                                open={inputVisible[index]}
                                onDropdownVisibleChange={(visible) => {
                                    setInputVisible(prev => ({
                                        ...prev,
                                        [index]: visible
                                    }));
                                }}
                                onChange={(selectedTags) => handleTagSelect(selectedTags, index)}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div 
                                            style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}
                                            onMouseDown={e => e.stopPropagation()}
                                        >
                                            <Input
                                                style={{ flex: 'auto', marginRight: '8px' }}
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                placeholder="New tag"
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addNewTag(index);
                                                    }
                                                }}
                                            />
                                            <Select
                                                style={{ width: 120, marginRight: '8px' }}
                                                value={selectedCategory}
                                                onChange={updateSelectedCategory}
                                                onClick={e => e.stopPropagation()}
                                                dropdownStyle={{ zIndex: 1100 }}
                                            >
                                                {tagCategories.map(category => (
                                                    <Option key={category.name} value={category.name}>
                                                        {category.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Button
                                                type="primary"
                                                onClick={() => addNewTag(index)}
                                                icon={<PlusOutlined />}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            >
                                {getUniqueTags(allTags).map(tag => (
                                    <Option 
                                        key={JSON.stringify(tag)}
                                        value={JSON.stringify(tag)}
                                        label={tag.text}
                                    >
                                        <Tag color={tagCategories.find(cat => cat.name === tag.category)?.color}>
                                            {tag.text}
                                        </Tag>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="form-group">
                            <label>Notes</label>
                            <ReactQuill 
                                value={entry.notes || ''} 
                                onChange={(value) => handleFieldChange('mood', `notes${index + 1}`, value)} 
                                modules={modulesWithoutTime}
                            />
                        </div>
                    </div>
                ))}
            </section>

            {/* Exercise Section */}             
            <section>
                <h2>Exercise</h2>
                <div className="exercise-row">
                    <div className="form-group">
                        <label>Walking Miles</label>
                        <input 
                            type="number" 
                            step="0.5" 
                            min="0" 
                            max="5" 
                            value={data.exercise.walkingMiles || 0}
                            onChange={(e) => handleFieldChange('exercise', 'walkingMiles', parseFloat(e.target.value))} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Ruck Weight (lbs)</label>
                        <input 
                            type="number" 
                            step="5" 
                            min="0" 
                            max="30" 
                            value={data.exercise.ruckWeight || 0}
                            onChange={(e) => handleFieldChange('exercise', 'ruckWeight', parseInt(e.target.value))} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Resistance?</label>
                        <input 
                            type="checkbox" 
                            checked={!!data.exercise.resistance}  // Ensure it's a boolean
                            onChange={(e) => handleFieldChange('exercise', 'resistance', e.target.checked)} 
                        />
                        {console.log("Rendering checkbox with state:", !!data.exercise.resistance)}
                    </div>
                </div>
            </section>

            {/* Medication Section */}
            <section>
            <h2>Medication</h2>
            {data.medication.map((entry, index) => (
                <div
                className={`medication-row ${index % 2 === 1 ? 'alternate-row' : ''}`}
                key={index}
                >
                {/* Medication Select Field */}
                <div className="form-group medication-select">
                    <label>Medication {index + 1}</label>
                    <select
                    value={entry.medication || ''}
                    onChange={(e) =>
                        handleFieldChange('medication', `medication${index + 1}`, e.target.value)
                    }
                    >
                    <option value="">Select Medication</option>
                    {medicationOptions.map((option, idx) => (
                        <option key={idx} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                </div>
                {/* Qty Field */}
                <div className="form-group">
                    <label>Qty</label>
                    <input
                    type="number"
                    min="0.25"
                    step="0.25"
                    value={entry.quantity}
                    onChange={(e) =>
                        handleFieldChange('medication', `quantity${index + 1}`, e.target.value)
                    }
                    />
                </div>
                {/* Time Field */}
                <div className="form-group">
                    <label>Time</label>
                    <input
                    type="time"
                    value={entry.time || ''}
                    onChange={(e) =>
                        handleFieldChange('medication', `time${index + 1}`, e.target.value)
                    }
                    step="900"
                    />
                </div>
                {/* Buttons */}
                <div className="medication-buttons">
                    <button onClick={() => handleAddEntry('medication')}>+</button>
                    <button
                    onClick={() => handleRemoveEntry('medication', index)}
                    disabled={index === 0 || data.medication.length === 1}
                    >
                    -
                    </button>
                </div>
                </div>
            ))}
            </section>

    {/* Footer Section */}
    <footer className="data-footer">
        <div className="button-container">
            <div className="notes-icon-wrapper" onClick={handleNotesIconClick}>
                <div className="icon-label-container">
                    <FileTextOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
                    <span className="icon-label">New Note</span>
                </div>
            </div>
            <div className="notes-icon-wrapper" onClick={handleViewNotesClick}>
                <div className="icon-label-container">
                    <EyeOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
                    <span className="icon-label">View Notes</span>
                </div>
            </div>
            <div className="notes-icon-wrapper" onClick={handleViewGraph}>
                <div className="icon-label-container">
                    <AreaChartOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
                    <span className="icon-label">View Graph</span>
                </div>
            </div>
            <div className="notes-icon-wrapper" onClick={handleViewData}>
                <div className="icon-label-container">
                    <DatabaseOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
                    <span className="icon-label">View Data</span>
                </div>
            </div>
            <div className="notes-icon-wrapper" onClick={handlePrintClick}>
                <div className="icon-label-container">
                    <PrinterOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
                    <span className="icon-label">Print</span>
                </div>
            </div>
            <div className="notes-icon-wrapper" onClick={fetchWeeklySummaryData}>
                <div className="icon-label-container">
                    <BarChartOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
                    <span className="icon-label">Summary</span>
                </div>
            </div>
        </div>
    </footer>

        <Modal
            title="Select Print Date Range"
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            okText="Print"
            cancelText="Cancel"
            style={{ backgroundColor: '#f2f2f2' }}
        >
            <RangePicker onChange={handleRangeChange} />
        </Modal>

        {/* Weekly Summary Modal */}
        <WeeklySummaryModal
        isVisible={isWeeklySummaryVisible}
        onClose={() => setIsWeeklySummaryVisible(false)}
        summaryData={weeklySummaryData}
        dateRange={dateRange}
        />

        {/* General Notes Modal */}
        <GeneralNotesModal
        isVisible={isGeneralNotesVisible}
        onClose={() => setIsGeneralNotesVisible(false)}
        onSave={handleSaveGeneralNotes}
        notes={generalNotes}
        onNotesChange={setGeneralNotes}
        />

        {/* View General Notes Modal */}
        <ViewNotesModal
            isVisible={isViewNotesVisible}
            onClose={() => setIsViewNotesVisible(false)}
            notes={allNotes}
        />
    </div>
);
}

export default MainPage;
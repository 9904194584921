import React from 'react';
import { 
    Moon,
    CircleOff,
    Circle,
} from 'lucide-react';

// Function to map pollen levels to color codes
const getPollenLevelColor = (level) => {
    switch(level) {
        case 'Very High':
            return '#0a5d00';  // Dark green
        case 'High':
            return '#1a8d00';  // Medium-dark green
        case 'Medium':
            return '#90EE90';  // Light green
        case 'Low':
            return '#e8ffe8';  // Very light green
        case 'Negligible':
            return 'transparent';  // No color
        default:
            return 'transparent';
    }
};

// Function to generate moon phase icons
const getMoonIcon = (phase) => {
    const iconProps = {
        size: 24,
        color: '#1a9cd4',
        className: 'moon-phase-icon',
        strokeWidth: 1.5
    };

    switch (phase) {
        case 'New Moon':
            return <CircleOff {...iconProps} />;
        case 'Waxing Crescent':
            return <Moon {...iconProps} fill="transparent" />;
        case 'First Quarter':
            return <Moon {...iconProps} fill="#1a9cd4" transform="scale(-1,1)" />;
        case 'Waxing Gibbous':
            return <Moon {...iconProps} fill="#1a9cd4" />;
        case 'Full Moon':
            return <Circle {...iconProps} fill="#1a9cd4" />;
        case 'Waning Gibbous':
            return <Moon {...iconProps} fill="#1a9cd4" transform="rotate(180)" />;
        case 'Last Quarter':
            return <Moon {...iconProps} fill="#1a9cd4" />;
        case 'Waning Crescent':
            return <Moon {...iconProps} fill="transparent" transform="rotate(180)" />;
        default:
            return <Moon {...iconProps} />;
    }
};

const WeatherInfo = ({ weatherData }) => {
    if (!weatherData) return <div>No weather data available for this date.</div>;

    return (
        <div className="weather-info">
            <div className="weather-row">
                <div className="weather-item">
                    <span className="weather-label">Temperature:</span>
                    <span className="weather-value">{weatherData.temperature}°F</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Weather:</span>
                    <span className="weather-value">{weatherData.description}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Pollen Level:</span>
                    <span className="weather-value">
                        <span 
                            className="pollen-indicator"
                            style={{
                                backgroundColor: getPollenLevelColor(weatherData.pollenLevel),
                                border: '1px solid #333',
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                marginRight: '8px',
                                verticalAlign: 'middle'
                            }}
                        />
                        {weatherData.pollenLevel}
                    </span>
                </div>
            </div>
            <div className="weather-row">
                <div className="weather-item">
                    <span className="weather-label">Sunrise:</span>
                    <span className="weather-value">{weatherData.sunrise}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Sunset:</span>
                    <span className="weather-value">{weatherData.sunset}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Daylight:</span>
                    <span className="weather-value">{weatherData.hoursOfDaylight}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Moon Phase:</span>
                    <span className="weather-value moon-phase" title={weatherData.moonPhase}>
                        {getMoonIcon(weatherData.moonPhase)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WeatherInfo;
import React from 'react';
import { Modal } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const GeneralNotesModal = ({ 
    isVisible, 
    onClose, 
    onSave, 
    notes, 
    onNotesChange
}) => {
    const handleSave = () => {
        onSave(notes);
    };

    const modules = {
        toolbar: {
            container: [
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image']
            ]
        }
    };

    return (
        <Modal
            title={
                <div style={{ textAlign: 'left', fontSize: '18px', marginBottom: '15px' }}>
                    General Notes
                </div>
            }
            visible={isVisible}
            onCancel={onClose}
            onOk={handleSave}
            okText="OK"
            cancelText="Cancel"
            width={800}
            bodyStyle={{ padding: '20px', paddingTop: '5px', paddingBottom: '40px' }}
        >
            <div className="general-notes-content">
                <ReactQuill
                    value={notes}
                    onChange={onNotesChange}
                    modules={modules}
                    style={{ 
                        height: '300px',
                        marginBottom: '0'
                    }}
                />
            </div>
        </Modal>
    );
};

export default GeneralNotesModal;